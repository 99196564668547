document.getElementById('searchBar').addEventListener('input', function (event) {
    const searchText = event.target.value;

    let filteredEvents = window.events.filter(function (event) {
        return event.title.toLowerCase().includes(searchText.toLowerCase()) ||
            event.description.toLowerCase().includes(searchText.toLowerCase()) ||
            event.places.some(function (place) {
                return place.name.toLowerCase().includes(searchText.toLowerCase());
            }) ||
            event.groups.some(function (group) {
                return group.name.toLowerCase().includes(searchText.toLowerCase());
            });
    });

    displayEvents(filteredEvents);

    const eventsContainer = document.getElementById("EventsTableBody");
    const textNodes = getTextNodes(eventsContainer);

    textNodes.forEach(node => {
        const content = node.nodeValue;

        const regex = new RegExp(`(${searchText})`, 'gi');
        if (content.match(regex)) {
            const span = document.createElement('span');
            span.innerHTML = content.replace(regex, '<span class="highlight">$1</span>');
            node.parentNode.replaceChild(span, node);
        }
    });
});

function getTextNodes(element) {
    let textNodes = [];
    element.childNodes.forEach(node => {
        if (node.nodeType === Node.TEXT_NODE) {
            textNodes.push(node);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            textNodes = textNodes.concat(getTextNodes(node));
        }
    });
    return textNodes;
}